import React, { useEffect, useState } from 'react'
import { PERSONALIZED_ICONS } from '../Constents'
import _ from 'lodash';
import { GetStartedThirdStyle } from './GetStartedProductPage.style';
import { addToCartMethod, isPercentageAssign, _getAllProduct } from '../CommonMethods';
import { useRouter } from 'next/router';
import { ChoodeConsentDialogStyle } from '@PagesComponent/KitFrom/KitStyle.style';
// import CodeComplexSection from 'src/pages/landing_page/CodeComplexSection';
import CodeComplexSection from 'src/pages/get_started/CodeComplexSection';
import { GiCheckMark } from 'react-icons/gi';

const GetStartedThird = ({ section = 'normal', ...props }) => {
    const [customProducts, setCustomProducts] = useState([])
    const PRODUCTS_SKU = ["SN-CC-CAPS-30-NEW", "SN-CC-CAPS-30", "SN-CC-PWDR-30-NEW", "SN-CC-PWDR-30",
        "SN-CC-CAPS-30-NEW_Subscription", "SN-CC-CAPS-30_Subscription", "SN-CC-PWDR-30-NEW_Subscription", "SN-CC-PWDR-30_Subscription"]

    const FILTER_PACKS = customProducts?.map((row) => row?.sku) || [];
    const matchingItemIDS = PRODUCTS_SKU?.filter(sku => FILTER_PACKS?.includes(sku));
    const optionValueCaps = matchingItemIDS?.[0] === "SN-CC-CAPS-30" ? "SN-CC-CAPS-30" : "SN-CC-CAPS-30-NEW"
    const optionValuePwdr = matchingItemIDS?.[1] === "SN-CC-PWDR-30" ? "SN-CC-PWDR-30" : "SN-CC-PWDR-30-NEW"
    const subscriptinValueCaps = matchingItemIDS?.[0] === "SN-CC-CAPS-30" ? "SN-CC-CAPS-30_Subscription" : "SN-CC-CAPS-30-NEW_Subscription"
    const subscriptinValuePwdr = matchingItemIDS?.[1] === "SN-CC-PWDR-30" ? "SN-CC-PWDR-30_Subscription" : "SN-CC-PWDR-30-NEW_Subscription"

    const router = useRouter();
    const [productData, setProductData] = useState([]);
    const [cartNotify, setCartNotify] = useState('');
    const [percentage, setPercentage] = useState(null);
    const [resultRelease, setResultRelease] = useState();
    const [selectedProduct, setSelectedProduct] = useState(matchingItemIDS?.[0] === "SN-CC-CAPS-30" ? 'SN-CC-CAPS-30_Subscription' : "SN-CC-CAPS-30-NEW_Subscription");
    const [product, setProduct] = useState({});
    const [autoshipCheck, setAutoshipCheck] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');


    useEffect(() => {
        setPercentage(isPercentageAssign())
        _getAllProduct().then((res) => {
            let OBJECT = res?.find(obj => obj?.sku === "SN-DNA-KIT");
            let OBJECT2 = res?.find(obj => obj?.sku === "SN-CC-CAPS-30" || obj?.sku === "SN-CC-CAPS-30-NEW")
            let OBJECT3 = res?.find(obj => obj?.sku === "SN-CC-PWDR-30" || obj?.sku === "SN-CC-PWDR-30-NEW");
            setProduct(OBJECT2)
            setProductData([OBJECT, OBJECT2])
            setCustomProducts([OBJECT2, OBJECT3])
        })
        const ProfileFreeKit = localStorage.getItem("profileData") ?
            JSON.parse(localStorage.getItem("profileData"))?.user_data?.userdetails?.[0]?.test_report_release : ""
        setResultRelease(ProfileFreeKit)
    }, [isPercentageAssign, _getAllProduct])

    function addProduct(redirect = false) {
        const URL = redirect ? router.push('/us/checkout/addresslist/') : ''
        productData?.map((row) => {
            addToCartMethod({
                id: row?.id, quantity: 1,
                is_autoship: row?.autoship_cost_price === "0.00" ? false : true
            },
                props?.setshowminicart,
                setCartNotify,
                props?.cartdata?.products,
                props?.setCartData,
                false,
                setErrorMsg,
                URL
            )
        })
    };

    function _onSelectChange(value) {
        const SPLIT_VALUE = _.split(value, '_');
        const SKU = SPLIT_VALUE[0];
        const SELECTED = _.find(customProducts, (row) => row?.sku === SKU);
        setSelectedProduct(value);
        if (SPLIT_VALUE[1]) {
            setAutoshipCheck(true)
        } else {
            setAutoshipCheck(false)
        }
        setProduct(SELECTED || null);
    }

    function addProductToCart() {
        const AUTOSHIP_VALUE = ["SN-CC-CAPS-30_Subscription", "SN-CC-PWDR-30_Subscription", "SN-CC-CAPS-30-NEW_Subscription", "SN-CC-PWDR-30-NEW_Subscription"]?.includes(selectedProduct) ? "autoship" : "one-time";
        const getUserAutoShip = JSON.parse(localStorage.getItem("profileData"))?.user_data?.userdetails?.[0]?.is_autoship_user || null;
        addToCartMethod({
            id: product?.id,
            quantity: 1,
            is_autoship: (getUserAutoShip && getUserAutoShip !== "False") || AUTOSHIP_VALUE === "one-time" ? false : true
        },
            props?.setshowminicart,
            setCartNotify,
            props?.cartdata?.products,
            props?.setCartData,
            false,
            setErrorMsg,
        )
    };

    let AUTOSHIP_PRICE = percentage ? product?.autoship_cost_price - product?.autoship_cost_price * (percentage / 100) : product?.autoship_cost_price;
    let COST_PRICE = percentage ? product?.cost_price - product?.cost_price * (percentage / 100) : product?.cost_price;
    const CUSTOM_PRODUCTS = props?.cartdata?.products?.filter((item) => item?.product?.is_custom_product === "True");


    return (
        <GetStartedThirdStyle>
            <div className='getStartedThird_section'>
                <div className='container'>
                    {router?.pathname !== "/get_started" &&
                        <CodeComplexSection product={product} />
                    }
                    <div className='image_and_icons_section'>
                        {router?.pathname === "/get_started" &&
                            <div className='precision nutrition'>
                                <h3>Science-Driven, DNA-Based Precision Nutrition for Whole Body Health</h3>
                                <div className='list-genetics'>
                                    <ul>
                                        <li>Made to order based on your genetics</li>
                                        <li>High-potency, bioactive ingredients</li>
                                        <li>Prebiotics + digestive enzymes</li>
                                        <li>Adaptogens and telomere support</li>
                                        <li>Non-GMO, gluten free</li>
                                        <li>3rd party tested</li>
                                        <li>Compounded in the USA</li>
                                    </ul>
                                </div>
                            </div>
                        }
                        <div className='image_personalized_section'>
                            {router?.pathname === "/get_started" &&
                                <>
                                    <div className='headingSection'>
                                        {section !== 'code-complex-review' ?
                                            <div className='tool-heading'>
                                                <h2 className='color'>TOOL #2</h2>

                                            </div> :
                                            <div className='code-tool-heading'>
                                                <h2 className='code-color'>crack the code for a healthier you.</h2>
                                            </div>

                                        }
                                        {section !== 'code-complex-review' ?
                                            <h3>Personalized<br />Nutrition</h3> :
                                            <h3>Nearly 400 million combinations possible.<br />One formulation for YOU.</h3>}

                                    </div>
                                    <div className='image_section'>
                                        {product?.product_images?.length > 0 && <img src={process.env.API_URL + product?.product_images?.[0]?.image} />}
                                    </div>
                                </>
                            }
                            <div className='contact-personalized'>
                                {product?.autoship_cost_price &&
                                    <>
                                        <h2>${AUTOSHIP_PRICE}/mo</h2>
                                        <h3>on subscription</h3>
                                    </>
                                }
                                {product?.cost_price &&
                                    <h3 className=''>${COST_PRICE} one-time purchase</h3>
                                }
                                <p className='dna-test-entry'>Requires one-time purchase of <a href='/get_started'>DNA Test Kit</a> or <a href='/dna-data-entry'>DNA Data Entry.</a></p>
                            </div>
                            {/* {resultRelease === true &&
                                <> */}
                            <div className='select-product'>
                                <select className="form-select-product" value={selectedProduct} onChange={(e) => _onSelectChange(e.target.value)}>
                                    {!(props?.subscribeAlready) &&
                                        <>
                                            <option value={subscriptinValueCaps}>CODE Complex DNA Personalized Nutrition (Subscription) - Capsules (30-day Supply) ${AUTOSHIP_PRICE}</option>
                                            <option value={subscriptinValuePwdr}>CODE Complex DNA Personalized Nutrition (Subscription) - Raw Unflavored Powder (30-day supply) ${AUTOSHIP_PRICE}</option>
                                        </>
                                    }
                                    <option value={optionValueCaps}>CODE Complex DNA Personalized Nutrition (One-time order) - Capsules (30-day Supply) ${COST_PRICE}</option>
                                    <option value={optionValuePwdr}>CODE Complex DNA Personalized Nutrition (One-time order) - Raw Unflavored Powder (30-day supply) ${COST_PRICE}</option>
                                </select>
                            </div>
                            <div className='add_to_cart_prooduct'>
                                <button title={CUSTOM_PRODUCTS?.length === 1 ? "You can add only one custom product" : ""} className='btn-primary-product' disabled={CUSTOM_PRODUCTS?.length === 1}
                                    onClick={() => addProductToCart()}>Add to Cart</button>
                            </div>
                            {errorMsg && <span className='error' style={{ color: "red" }}>{errorMsg}</span>}

                            {/* </>
                            } */}
                        </div>
                        {router?.pathname === "/get_started" &&
                            <div className='right-product'>
                                <div className='personalized_icons'>
                                    {_.map(PERSONALIZED_ICONS, ({ icon, text }, index) => (
                                        <div key={index} className='iconContainer'>
                                            <div className='small_image_section'><img src={`/images/${icon}`} /></div>
                                            <p>{text}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <h4>
                    Based on your personal nutritional needs, as evidenced by the genetic analysis of
                    your cheek swab, CODE Complex contains the vitamins, minerals, and
                    micronutrients your body requires for optimal functioning.
                </h4>
                <h2 className='lower_heading'>
                    <span onClick={() => addProduct()} style={{ cursor: 'pointer' }}>
                        <span className='blackColor'>Subscribe today to unlock</span><br />
                        <span className='whiteColor'>up to $30 per month in savings</span><br />
                        <span className='whiteColor' style={{ fontSize: "18px" }}>plus *Free shipping to the U.S. only.</span>
                    </span>
                </h2>
                {router?.pathname === "/get_started" &&
                    <div className='white_strip_icons'>
                        <div><GiCheckMark /> Non-GMO </div>
                        <div><GiCheckMark />Manufactured in an NSF Certified, cGMP Compliant and FDA Registered facility. </div>
                        <div><GiCheckMark /> Third-party tested </div>

                    </div>
                }
            </div>
        </GetStartedThirdStyle >
    )
}

export default GetStartedThird